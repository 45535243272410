import Vue from 'vue'

var ws = {
  showNotice: null,
  closeNotice: null,
  getMessageList: null
}
ws.init = function () {
  var userId = localStorage.getItem('userId')
  if (userId != 0 && userId != '') {
    let baseUrl = process.env.VUE_APP_BASEURL
    let wsKey = process.env.VUE_APP_WSK
    let wsPath = '/api/websocket?userId=' + userId
    let wsUrl = ''
    if (process.env.NODE_ENV === 'production') { // 线上
      wsUrl = baseUrl.replace(/(http|https):(.*?)/, wsKey + '$2') + wsPath
    } else { // 本地
      wsUrl = wsKey + '//' + window.location.host + '/proxyApi' + wsPath
    }
    let timer = null
    const websocket = new WebSocket(wsUrl)
    websocket.onopen = function () {
      console.log('open')
      // keepAlive
      timer = setInterval(() => {
        if (websocket != null && websocket != undefined) {
          websocket.send('keepAlive')
        }
      }, 20000)
    }
    websocket.onmessage = function (e) {
      console.log('接收到后台消息为: ' + e.data)
      const data = JSON.parse(e.data)
      console.log(ws.showNotice)
      ws.showNotice(data)
    }
    websocket.onclose = function () {
      console.log('close')
      clearInterval(timer)
      ws.closeNotice()
    }
    Vue.prototype.$ws = websocket
  }
}

export default ws
