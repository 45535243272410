import swiper from './swiper.vue' //barner
import header from './header.vue' //header
import footer from './footer.vue' //footer

const componentsLib = {
  components: [
    swiper, //banner  ab-swiper
    header, //header  ab-header
    footer, //footer  ab-footer
  ],
  install: function (Vue) {
    this.components.map(component => {
      Vue.component(component.name, component)
    })
  }
}

export default componentsLib
