import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookie from 'vue-cookie'
import utils from './utils/utils.js'
import calculation from './utils/calculation.js'
import http, { post, fetch } from './utils/https.js'
import ws from './utils/ws.js'

import 'swiper/dist/css/swiper.css'
import './assets/font/iconfont.css'
import './assets/css/element-variables.scss'
import './assets/css/website.scss'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import ElementUI from 'element-ui'
import componentLib from './components/index.js'
import icons from './icons/index.js'

Vue.use(VueCookie)
Vue.use(VueAwesomeSwiper)
Vue.use(ElementUI)
Vue.use(componentLib)

Vue.config.productionTip = false
Vue.prototype.$isAuth = utils.tools.isAuth     // 权限方法
Vue.prototype.$http = http 
Vue.prototype.$get = fetch // ajax请求方法，封装 get
Vue.prototype.$post = post // ajax请求方法, 封装post
Vue.prototype.$utils = utils.tools
Vue.prototype.$calc = calculation
Vue.prototype.$initSessionStorageData = utils.initSessionStorageData
Vue.prototype.ws = ws // ws链接
Vue.prototype.$loading = ElementUI.Loading.service
Vue.prototype.$msgbox = ElementUI.MessageBox
Vue.prototype.$alert = ElementUI.MessageBox.alert
Vue.prototype.$confirm = ElementUI.MessageBox.confirm
Vue.prototype.$prompt = ElementUI.MessageBox.prompt
Vue.prototype.$notify = ElementUI.Notification
Vue.prototype.$message = ElementUI.Message

Object.keys(utils.filters).forEach(key => {
  Vue.filter(key, utils.filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
