import Vue from 'vue'
import ELEMENT from 'element-ui'
import httpRequest from './https.js'
import store from '../store/index.js'
import http, { post, fetch } from './https.js' // api: https://github.com/axios/axios

const tools = {
  /**
 * 设置localStorage
 * @key  @value
 * */
  setLocalStorage (key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  /****
   * 获取localStorage的值
   * @key
   */
  getLocalStorage (key) {
    return JSON.parse(window.localStorage.getItem(key))
  },
  /**
   * 删除localStorage
   * @param key
   */
  removeLocalStorage (key) {
    window.localStorage.removeItem(key)
  },
  /**
 * 删除 all localStorage
 * @param
 */
  clearAllLocalStorage () {
    window.localStorage.clear()
  },
  /**
   * set sionStorage
   * @key  @value
   * */
  setSessionStorage (key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  },
  /****
   * 获取sessionStorage的值
   * @key
   */
  getSessionStorage (key) {
    return JSON.parse(window.sessionStorage.getItem(key))
  },
  /**
   * 删除sessionStorage
   * @param key
   */
  removeSessionStorage (key) {
    window.sessionStorage.removeItem(key)
  },
  /**
   * 删除 all localStorage
   * @param
   */
  clearAllSessionStorage () {
    window.sessionStorage.clear()
  },

  /**
   * text转换成html
   * @param str
   */
  textToHtml (str) {
    if (str) {
      return str.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/ /g, '&nbsp;')
    }
    return str
  },

  // 根据url取tab数据
  getTabDataByUrl (url, isClient = false) {
    let data = {
      type: 'router',
      path: url,
      query: {}
    }
    if (/^http[s]?:\/\/.*/.test(url)) {
      if (url.includes('/#/')) {
        let simpleUrl = '/' + url.split('/#/')[1]
        data.path = simpleUrl.includes('?') ? simpleUrl.split('?')[0] : simpleUrl
      } else {
        data.type = 'window'
      }
    } else {
      if (url.includes('?')) {
        data.path = url.split('?')[0]
      }
    }
    if (data.type === 'router') {
      let param = this.getUrlParam(url)
      if (data.path.substr(0, 1) !== '/') {
        data.path = '/' + data.path
      }
      data.query = Object.keys(param).length ? Object.assign({}, param, { cache: false, t: new Date().getTime() }) : {}
    }
    return data
  },

  getUrlParam (url) {
    let param = {}
    if (url.includes('?')) {
      let str = url.split('?')[1]
      let arr = str.includes('&') ? str.split('&') : [str]
      arr.forEach(e => {
        if (e.includes('=')) {
          param[e.split('=')[0]] = decodeURI(e.split('=')[1])
        }
      })
    }
    return param
  },

  setActiveMenu (menuId) {
    let index = this.getTopMenuIndex(menuId)
    if (index) {
      store.commit('common/updateActiveMenu', Number(index))
    }
  },

  // 找一级菜单index
  getTopMenuIndex (id, topIndex, list) {
    let menuList = (list || this.getSessionStorage('menuList') || []).filter(e => e.isShow)
    let result
    for (let index in menuList) {
      let item = menuList[index]
      if (item.id === id) {
        result = topIndex || index
        return result
      } else if (item.children && item.children.length) {
        if (item.level === 1)  {
          topIndex = index
        }
        result = this.getTopMenuIndex(id, topIndex, item.children)
        if (result) {
          return result
        }
      }
    }
    return result
  },

  /**
   * @param {*} date
   * @param {*} format 格式化参数
   */
  formatDate (date, formatString = 'yyyy-MM-dd') {
    if (!date) {
      return ''
    }
    var t = new Date(date)
    var tf = function (i) {
      return (i < 10 ? '0' : '') + i
    }
    return formatString.replace(/yyyy|yy|MM|dd|HH|mm|ss/g, function (a) {
      switch (a) {
        case 'yyyy':
          return tf(t.getFullYear())
        case 'yy':
          return tf(t.getFullYear()).substring(2)
        case 'MM':
          return tf(t.getMonth() + 1)
        case 'mm':
          return tf(t.getMinutes())
        case 'dd':
          return tf(t.getDate())
        case 'HH':
          return tf(t.getHours())
        case 'ss':
          return tf(t.getSeconds())
      }
    })
  },

  isAuth (key) {
	 return JSON.parse(sessionStorage.getItem('permissions') || '[]').includes(key)
  },

  clearLoginInfo () {
    let baseUrl = process.env.VUE_APP_DOADMIN
    Vue.cookie.delete('token', { domain: baseUrl })
    sessionStorage.clear()
    localStorage.removeItem('caseSet', '')
    localStorage.removeItem('loginid', '')
    localStorage.removeItem('userId', '')
    localStorage.removeItem('tenantid', '')
  }
}

const filters = {
  /**
  * 格式化千位符,钱的格式,
  * @num  10000
  * return  1,000
  * **/
  formatMoney (num, isDecimal) {
    num = filters.formatNumber(num)
    if (isNaN(num)) {
      return ''
    }
    if (!isDecimal) {
      num = Number(num).toFixed(2)
    }
    if (!/^(\+|-)?(\d+)(,\d{3})*(\.|\.\d+)?$/.test(num)) {
      return num
    }
    let re = /(\d)(\d{3})(,|$)/, a = RegExp.$1, b = RegExp.$2, c = RegExp.$4
    while (re.test(b)) {
      b = b.replace(re, '$1,$2$3')
    }
    let value = a + '' + b + '' + c
    return value
  },
  /**
  * 还原number 类型,
  * @strval  1,000
  * return 1000
  * **/
  formatNumber (str) {
    str += ''
    if (str.indexOf(',') === -1) {
      return str
    }
    return str.replace(/,/g, '')
  },
  /***
  * 格式化数字类型,保留2位小数
  * @x  100
  * return 100.00
  * **/
  toDecimal2 (x) {
    if (!x) {
      return ''
    }
    let s = x.toString()
    let rs = s.split('.')
    let left = rs[0]
    let right = rs[1]
    if (right) {
      if (right.length > 2) {
        right = right.substring(0, 2)
      } else if (right.length < 2) {
        right += 0
      }
    } else {
      right = '00'
    }
    return left + '.' + right
  },
  formatDate (date, mat) {
    return tools.formatDate(date, mat)
  }
}

// 初始化全局数据

const initSessionStorageData = {
  init () {
    return Promise.all([])
  }
}

export default {
  tools,
  filters,
  initSessionStorageData
}