<template>
  <div class="site-footer">
    <div class="bg">
      <img src="@/assets/img/website/ft-bg.png" />
    </div>
    <div class="sf-main">
      <div class="menu">
        <div
          class="item"
          :class="{ 'current': activeMenu === '/index' }"
          @click="() => { this.$router.push('/index') }"
        >首页</div>
        <div
          class="item"
          :class="{ 'current': activeMenu === '/service' }"
          @click="() => { this.$router.push('/service-transport-abroad') }"
        >产品服务</div>
        <div
          class="item"
          :class="{ 'current': activeMenu === '/about' }"
          @click="() => { this.$router.push('/about') }"
        >关于芯起</div>
        <div
          class="item"
          :class="{ 'current': activeMenu === '/contact' }"
          @click="() => { this.$router.push('/contact') }"
        >联系我们</div>
      </div>
      <div class="contact">
        <div class="address">
          上海市黄浦区西藏中路18号港陆广场2102室<br />
          深圳市南山区前海深港合作区金融街1号弘毅大厦9楼<br />
          湖南省长沙市高新区文轩路27号麓谷钰园F2栋24层
        </div>
        <div class="other">
          <div class="mobile">+86 138 1678 4278 / 191 2174 0157</div>
          <div class="email">jason.shen@cicsc.com/zhu.jie@cicsc.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ab-footer',

  props: {

  },

  data() {
    return {

    }
  },

  mounted() {
    
  },

  computed: {
    activeMenu() {
      const path = this.$route.path
      return path.split('-')[0]
    }
  },

  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss">
.site-footer {
  position: relative;
  width: 100%;
  margin-top: 150px;
  .bg {
    position: relative;
    z-index: 1;
    font-size: 0;
    pointer-events: none;
    img {
      width: 100%;
    }
  }
  .sf-main {
    position: absolute;
    top: 10%;
    left: 50%;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1200px;
    height: 80%;
    margin: 0 0 0 -600px;
    .menu {
      display: flex;
      justify-content: space-between;
      .item {
        height: 55px;
        padding-left: 50px;
        font-size: 24px;
        line-height: 55px;
        color: #fff;
        border-bottom: 1px solid transparent;
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 35px;
        cursor: pointer;
        &:nth-child(1) {
          background-image: url("../assets/img/website/menu-icon-1.png");
        }
        &:nth-child(2) {
          background-image: url("../assets/img/website/menu-icon-2.png");
        }
        &:nth-child(3) {
          background-image: url("../assets/img/website/menu-icon-3.png");
        }
        &:nth-child(4) {
          background-image: url("../assets/img/website/menu-icon-4.png");
        }
        &:hover, &.current {
          border-color: #fff;
          font-weight: 700;
        }
      }
    }
    .contact {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      line-height: 40px;
      color: #fff;
      .address {
        padding-left: 50px;
        background: url("../assets/img/website/ft-address.png") no-repeat left 8px;
        background-size: 30px;
      }
      .mobile {
        padding-left: 50px;
        background: url("../assets/img/website/ft-mobile.png") no-repeat left 6px;
        background-size: 30px;    
      }
      .email {
        padding-left: 50px;
        background: url("../assets/img/website/ft-email.png") no-repeat left 6px;
        background-size: 30px;
      }
    }
  }
}
</style>

