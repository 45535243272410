/*    在组件内需要调用，可以通过VUEX的MAP辅助函数
 *   import { mapState, mapMutations, mapActions } from 'vuex'
 *   ...mapState ('user',['id'])                   // 获取state值{{this.id}}
 *   ...mapMutations('user', ['updateId'])         // 不通过异步来修改 this.UpdateId(value) 改变state值
 *   ...mapActions('user', [ 'ActionsTenantId'])   // 通过异步 this.ActionsTenantId(value) 改变state值
 *
 *
 *这里用了localStorag存放state的值,解决了刷新浏览器所有状态都会初始化。
 *在某些浏览器上用户关闭了本地存储或者使用隐身模式，会导致浏览器抛出异常
 *如果用了本地存储，在外层包裹上try{}catch(e){}
 */
let [defaultid, defaultTenantid, defaultname, defaultUserId] = ['0', '0', '0', '0']
try {
  if (localStorage.loginname || localStorage.loginid || localStorage.tenantid || localStorage.userId) {
    defaultname = localStorage.loginname
    defaultid = localStorage.loginid
    defaultTenantid = localStorage.tenantid
    defaultUserId =  localStorage.userId
  }
} catch (e) {

}
const user = {
  namespaced: true,
  state: {
    userId: defaultUserId,
    loginid: defaultid, // 登录id
    tenantid: defaultTenantid, // 租户id
    loginname: defaultname
  },
  mutations: {
    updateUserId (state, id) {
      state.userId = id
      try {
        localStorage.userId = id
      } catch (e) { }
    },
    updateLoginId (state, id) {
      state.loginid = id
      try {
        localStorage.loginid = id
      } catch (e) { }
    },
    updateTenantId (state, id) {
      state.tenantid = id
      try {
        localStorage.tenantid = id
      } catch (e) { }
    },
    updateName (state, name) {
      state.loginname = name
      try {
        localStorage.loginname = name
      } catch (e) { }
    }
  },
  actions: {
    ActionsLoginId (ctx, value) {
      ctx.commit('updateLoginId', value)
    },
    ActionsUserId (ctx, value) {
      ctx.commit('updateUserId', value)
    },
    ActionsTenantId (ctx, value) {
      ctx.commit('updateTenantId', value)
    },
    ActionsUpdateName (ctx, value) {
      ctx.commit('updateName', value)
    }
  }
}
export default user
