<template>
    <div class="banner-wrap" ref="bannerWrap">
        <swiper ref="swiper" :options="options" v-if="listArry && listArry.length > 0" :style="'height:' + (height ? height + 'px' : '100%')">
            <swiper-slide v-for="(obj,key) in listArry" :key="key">
              <img :data-src="obj[keyWord] || loadingSrc" :src="loadingSrc" class="swiper-lazy" />
            </swiper-slide>
          <!-- Optional controls -->
          <div class="swiper-pagination" slot="pagination" v-if="!onlyOne && pagination"></div>
          <div class="swiper-button-prev iconfont icon-arrow" slot="button-prev" v-if="!onlyOne && prevNext"></div>
          <div class="swiper-button-next iconfont icon-arrow" slot="button-next" v-if="!onlyOne && prevNext"></div>
        </swiper>
    </div>
</template>

<script>
let vm = null

export default {
    name: 'ab-swiper',
    model: {
        prop: 'listArry',
        event: 'change'
    },
    data () {
        return {
            flag: true,
            height: '',
            loadingSrc: require('../assets/img/' + this.loadingPic),
            options: {},
            onlyOne: true
        }
    },
    props: {
        ratio: {
            type: Number | String,
            default: ''
        },
        keyWord: {
            type: String,
            default: 'url'
        },
        pagination: {
            type: Boolean,
            default: true
        },
        prevNext: {
            type: Boolean,
            default: true
        },
        loadingPic: {
            type: String,
            default: 'banner-loading.jpg'
        },
        listArry: {
            type: Array
        },
        swiperOptions: {
            type: Object,
            default: () => {
                return {
                    lazy: true,
                    loadPrevNext: true,
                    loop: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false
                    },
                    speed: 1000,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    on:{
                        click: function(swiper) {
                            if (swiper.target.className.includes('swiper-lazy')) {
                                vm.handleClick(this.realIndex)
                            }
                        }
                    }
                }
            }
        }
    },

    watch: {
        listArry(arr) {
            let options = Object.assign({}, this.swiperOptions)
            if (arr.length <= 1) {
                options.loop = false
                options.autoplay = false
                this.onlyOne = true
            } else {
                this.onlyOne = false
            }
            this.options = options
        }
    },

    destroyed(){
        window.removeEventListener('resize', this.setHeight)
    },

    mounted () {
        let options = Object.assign({}, this.swiperOptions)
        if (this.listArry.length <= 1) {
            options.loop = false
            options.autoplay = false
        }
        this.options = options
        vm = this
        window.addEventListener('resize', this.setHeight)
        this.setHeight()
    },

    methods: {
        setHeight() {
            if (this.ratio) {
                this.height = this.$refs.bannerWrap.clientWidth * this.ratio
                this.$emit('height', this.height)
            }
        },

        handleClick (index) {
            this.$emit('handleClick', this.listArry[index])
        }
    }
}
</script>

<style lang="scss">
.banner-wrap {
  width: 100%;
  height: 100%;
  .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-slide {
          height: 100%;
          img {
              width: 100%;
              height: 100%;
          }
      }
  }
}
</style>
