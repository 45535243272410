import Vue from 'vue'
import axios from 'axios'
import ELEMENT from 'element-ui'

const http = axios.create({
  timeout: 1000 * 50,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  },

  // errorCode处理
  validateStatus: (status) => {
    return true
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  let token = Vue.cookie.get('TOKEN')
  token && (config.headers['Authorization'] = 'Bearer ' + Vue.cookie.get('TOKEN')) // 请求头带上token
  if (config.method === 'get') {
    if (config.url.includes('?')) {
      config.url += '&_='
    } else {
      config.url += '?_='
    }
    config.url += new Date().getTime()
  }
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data.code === 'E0003') { // token失效
    Vue.cookie.set('TOKEN', null)
    //window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#/login'
    return response
  }
  if (response.data.code !== 'C0000') {
	  ELEMENT.Message({
      message: response.data.message,
      type: 'error',
      duration: 3 * 1000
    })
  }
  
  return response
}, error => {
  if (error.message.includes('timeout')) {
    
  } else {
    
  }
  return Promise.reject(error)
})

http.adornUrl = (actionName) => {
 // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
 let baseUrl = process.env.VUE_APP_BASEURL
 actionName = '/api' + actionName
 return process.env.NODE_ENV !== 'production' ? '/proxyApi' + actionName : baseUrl + actionName
}

http.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
	var defaults = {
		't': new Date().getTime()
	}
	data = openDefultdata ? merge(defaults, data) : data
	
	return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}
/**
 * 封装get请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch (url, params = {}) {
  return new Promise((resolve, reject) => {
    http.get(http.adornUrl(url), {
      params: params
    })
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    http.post(http.adornUrl(url), data)
      .then(response => {
        resolve(response)
      }, err => {
        reject(err)
      }).finally(() => {
   
      })
  })
}

export default http