<template>
  <div id="app">
    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data () {
    return {
      
    }
  },

  mounted () {
    
  },

  methods: {
    
  }
}
</script>

<style lang="scss">

</style>
