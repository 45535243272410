<template>
  <div class="site-header">
    <div class="sh-main clearfix">
      <div class="logo" @click="$router.push('/index')"></div>
      <el-menu
        :default-active="activeIndex"
        class="site-nav"
        text-color="#000"
        active-text-color="#009FE8"
        mode="horizontal"
        router
      >
        <el-menu-item index="/index">首页</el-menu-item>
        <el-submenu index="/service">
          <template #title>产品服务</template>
          <el-menu-item index="/service-transport-abroad">国际运输</el-menu-item>
          <el-menu-item index="/service-transport-domestic">国内运输</el-menu-item>
          <el-menu-item index="/service-storage-custom">仓储及清关</el-menu-item>
          <el-menu-item index="/service-related">配套服务</el-menu-item>
        </el-submenu>
        <el-menu-item index="/about">关于芯起</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ab-header',

  props: {

  },

  data() {
    return {
      activeIndex: '/index'
    }
  },

  mounted() {
    let path = this.$route.path
    if (path.includes('-detail')) {
      this.activeIndex = path.replace('-detail', '-list')
    } else {
      this.activeIndex = path
    }
  },

  computed: {

  },

  watch: {

  },

  methods: {

  }
}
</script>

<style lang="scss">
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 94px;
  background: #fff;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.05);
  .sh-main {
    width: 1240px;
    margin: 0 auto;
  }
  .logo {
    float: left;
    width: 238px;
    height: 60px;
    margin-top: 17px;
    background: url("../assets/img/website/logo.png") no-repeat;
    background-size: contain;
    cursor: pointer;
  }
  .site-nav {
    float: right;
    border: none;
    background: none;
    > .el-submenu {
      margin: 0 50px;
      padding-top: 17px;
      .el-submenu__title {
        height: 60px;
        padding: 0;
        border: none;
        font-size: 26px;
        line-height: 60px;
      }
    }
    .el-menu-item {
      height: 94px;
      margin: 0 50px;
      padding: 0;
      border: none;
      font-size: 26px;
      line-height: 94px;
      &:last-child {
        margin-right: 0;
      }
      &:hover, &.is-active {
        background: none;
      }
    }
  }
}
</style>
