/**
 * 全站路由配置
 *
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import http from '../utils/https.js'
import store from '../store/index'
import ELEMENT from 'element-ui'
import utils from '../utils/utils.js'
import routerConfig from './router-detail-config'
Vue.use(ELEMENT)
Vue.use(Router)

// 解决vue路由重复导航错误
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err))
}

// 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢, 所以只有生产环境使用懒加载
const _import = require('./import-' + process.env.NODE_ENV)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  {
    path: '/404',
    component: () => import('../components/404.vue'),
    name: '404',
    meta: {
      title: '404未找到'
    }
  },
  // {
  //   path: '/login',
  //   component: () => import('../views/main-modules/login.vue'),
  //   name: 'login',
  //   meta: {
  //     title: '登录'
  //   }
  // },
  {
    path: '/index',
    component: () => import('../views/website/home.vue'),
    name: 'index',
    meta: {
      title: '首页'
    }
  },
  {
    path: '/service-transport-abroad',
    component: () => import('../views/website/service-transport-abroad.vue'),
    name: 'course-list',
    meta: {
      title: '国际运输'
    }
  },
  {
    path: '/service-transport-domestic',
    component: () => import('../views/website/service-transport-domestic.vue'),
    name: 'service-transport-domestic',
    meta: {
      title: '国内运输'
    }
  },
  {
    path: '/service-storage-custom',
    component: () => import('../views/website/service-storage-custom.vue'),
    name: 'service-storage-custom',
    meta: {
      title: '仓储及清关'
    }
  },
  {
    path: '/service-related',
    component: () => import('../views/website/service-related.vue'),
    name: 'service-related',
    meta: {
      title: '配套服务'
    }
  },
  {
    path: '/about',
    component: () => import('../views/website/about.vue'),
    name: 'about',
    meta: {
      title: '关于芯起'
    }
  },
  {
    path: '/contact',
    component: () => import('../views/website/contact.vue'),
    name: 'contact',
    meta: {
      title: '联系我们'
    }
  }
]

// 主入口路由(需嵌套上左右整体布局)
const mainRoutes = {
  path: '/',
  component: () => import('../views/main-modules/main.vue'),
  name: 'main',
  redirect: {
    name: 'index'
  },
  meta: {
    title: '主入口整体布局'
  },
  children: [
    // 通过meta对象设置路由展示方式
    // 1. isTab: 是否通过tab展示内容, true: 是, false: 否
    // 2. iframeUrl: 是否通过iframe嵌套展示内容, '以http[s]://开头': 是, '': 否
    // 提示: 如需要通过iframe嵌套展示内容, 但不通过tab打开, 请自行创建组件使用iframe处理!
    {
      path: '/home',
      component: () => import('../views/main-modules/home.vue'),
      name: 'home',
      meta: {
        title: '主页',
        isTab: true
      }
    },
    // {
    //   path: '/theme',
    //   component: () => import('common/theme'),
    //   name: 'theme',
    //   meta: {
    //     title: '主题'
    //   }
    // },
    ...routerConfig
  ],
  beforeEnter (to, from, next) {
    let token = Vue.cookie.get('token')
    // if (to.name === 'login' && token) {
    //   next({ name: to.name })
    // } else if (!token || !/\S/.test(token)) {
    //   clearLoginInfo()
    //   next({ name: 'login' })
    // }
    if (!token || !/\S/.test(token)) {
      utils.tools.clearLoginInfo()
      next({ name: 'login' })
    }
    next()
  }
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({
    y: 0
  }),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
  let token = Vue.cookie.get('token')
  if (!token) {
    router.options.isAddDynamicMenuRoutes = false
  }
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  let menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]').filter(e => e.isShow)
  let lastUpdateTime = JSON.parse(sessionStorage.getItem('lastUpdateTime'))
  if (menuList && menuList.length && lastUpdateTime) {
    const _EXPIRE_TIME = 12 * 60 * 60 * 1000 // 超时时间， 12小时
    if ((new Date()).getTime() - lastUpdateTime > _EXPIRE_TIME) {  // 过期了
      sessionStorage.removeItem('menuList')
      sessionStorage.removeItem('lastUpdateTime')
      menuList = []
      router.options.isAddDynamicMenuRoutes = false
    }
  }
  if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to) === 'global') {
    next()
  } else if (!token || !/\S/.test(token) && to.fullPath !== '/login') {
    utils.tools.clearLoginInfo()
    ELEMENT.Message({
      message: '用户失效，请重新登录',
      type: 'error'
    })
    next({ name: 'login', replace: true })
  } else if (menuList && menuList.length) {
    fnAddDynamicMenuRoutes(menuList)
    router.options.isAddDynamicMenuRoutes = true
    next({
      ...to,
      replace: true
    })
  } else {
    console.log('userId:    ' + store.state.user.tenantid)
    let url = ''
    if (store.state.user.loginid) {
      url = '/p/menu/queryTreeByUserIdAndAppId'
    } else {
      url = '/p/menu/queryTree'
    }
    http({
      url: http.adornUrl(url),
      method: 'post',
      data: {
        'userId': store.state.user.tenantid,  // 租户id
        'businessType': 'ZHOT'
      }
    }).then(({ data }) => {
      if (data && data.code === 'C0000') {
        let resultList = data.result.menuList
        fnAddDynamicMenuRoutes(resultList)
        router.options.isAddDynamicMenuRoutes = true
        sessionStorage.setItem('menuList', JSON.stringify(resultList || '[]'))
        sessionStorage.setItem('lastUpdateTime', new Date().getTime())
        sessionStorage.setItem('permissions', JSON.stringify(data.result.permissions || '[]'))
        next({
          ...to,
          replace: true
        })
      } else {
        sessionStorage.setItem('menuList', '[]')
        sessionStorage.setItem('permissions', '[]')
        next()
      }
    })
  }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType (route) {
  var temp = []
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  var temp = []
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].url && /\S/.test(menuList[i].url)) {
      menuList[i].url = menuList[i].url.replace(/^\//, '')
      var route = {
        path: menuList[i].url.replace('/', '-'),
        component: null,
        name: menuList[i].url.replace('/', '-'),
        meta: {
          menuId: menuList[i].id,
          title: menuList[i].name,
          isDynamic: true,
          isTab: true,
          iframeUrl: ''
        }
      }
      try {
        let filePach = ''
        if (/^\//.test(menuList[i].url)) {
          filePach = `views${menuList[i].url}`
        } else {
          filePach = `views/${menuList[i].url}`
        }
        route['component'] = _import(filePach) || null
      } catch (e) { }
      routes.push(route)
    }
    if (menuList[i].children && menuList[i].children.length >= 1) {
      temp = temp.concat(menuList[i].children)
    }
  }
  if (temp.length >= 1) {
    fnAddDynamicMenuRoutes(temp, routes)
  } else {
    mainRoutes.name = 'main-dynamic'
    mainRoutes.children = routes
    router.addRoutes([
      mainRoutes,
      {
        path: '*',
        redirect: {
          name: '404'
        }
      }
    ])
    sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
  }
}

export default router
