export default {
  namespaced: true,
  state: {
    // 页面文档可视高度(随窗口改变大小)
    documentClientHeight: 0,
    // 导航条, 布局风格, default(默认) / inverse(反向)
    navbarLayoutType: 'default',
    // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
    sidebarLayoutSkin: 'dark',
    // 侧边栏, 折叠状态
    sidebarFold: false,
    // 侧边栏, 菜单
    menuList: [],
    menuActiveName: '',
    // 主入口标签页
    mainTabs: [],
    haveBizType:{
      JW: false,
      CW: false
    },// 签署的业务类型
    currBizType: '',// 工作台切换的业务类型
    nowMoney: 0,  // 账户余额
    balanceIntegral: 0,  //积分
    coupon: 0,  //  优惠券
    showCouponDia: true,  // 是否有优惠券弹框
    mainTabsActiveName: '',
    unlocking: {},
    workbench: {},
    activeMenu: 0, // fin主菜单当前选择的项
    messageList: [], // 未读消息
    step: '',
    isClient: false
  },
  mutations: {
    updaetNowMoney(state, value) {
      state.nowMoney = value
    },
    updaetBalanceIntegral(state, value) {
      state.balanceIntegral = value
    },
    updaetCoupon(state, value) {
      state.coupon = value
    },
    updaetCouponDia(state, value) {
      state.showCouponDia = value
    },
    updateDocumentClientHeight (state, height) {
      state.documentClientHeight = height
    },
    updateNavbarLayoutType (state, type) {
      state.navbarLayoutType = type
    },
    updateSidebarLayoutSkin (state, skin) {
      state.sidebarLayoutSkin = skin
    },
    updateSidebarFold (state, fold) {
      state.sidebarFold = fold
    },
    updateMenuList (state, list) {
      state.menuList = list
    },
    updateMenuActiveName (state, name) {
      state.menuActiveName = name
    },
    updateMainTabs (state, tabs) {
      state.mainTabs = tabs
    },
    updateHaveBizType (state, obj) {
      state.haveBizType = obj
    },
    changeBizType (state, value) {
      state.currBizType = value
    },
    updateMainTabsActiveName (state, name) {
      state.mainTabsActiveName = name
    },
    updateTabTitle (state, obj) {
      state.mainTabs[obj.index].title = obj.name
    },
    SET_OPEN (state, value) {
      state.unlocking = value
    },
    updateActiveMenu (state, name) { // fin
      state.activeMenu = name
    },
    SET_WORK (state, value) {
      state.workbench = value
    },
    SET_MESSAGE_LIST (state, value) {
      state.messageList = value
    },
    IS_ClIENT (state, value) {
      state.isClient = value
    },
    SET_STEP (state, value) {
      state.step = value
    }
  },
  actions: {
    SET_OPEN (ctx, value) {
      ctx.commit(SET_OPEN, value)
    },
    SET_WORK (ctx, value) {
      ctx.commit(SET_WORK, value)
    },
    SET_MESSAGE_LIST (ctx, value) {
      ctx.commit(SET_MESSAGE_LIST, value)
    },
    IS_ClIENT (ctx, value) {
      ctx.commit(IS_ClIENT, value)
    },
    SET_STEP (ctx, value) {
      ctx.commit(SET_STEP, value)
    }
  }
}
